<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template
          #default
        >
          <tbody>
            <tr>
              <td>
                <v-chip
                  color="grey"
                  dark
                  label
                >
                  x %
                </v-chip>
              </td>
              <td>
                Porcentaje de preguntas contestadas del checklist
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  color="red"
                  dark
                  label
                >
                  x %
                </v-chip>
              </td>
              <td>
                Porcentaje de preguntas contestadas del checklist (con anomalías)
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  color="green"
                  dark
                  label
                >
                  100 %
                </v-chip>
              </td>
              <td>
                Todas las preguntas del checklist contestadas (sin anomalías)
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'

export default {
  mixins: [internalShowingMixin],
}
</script>
